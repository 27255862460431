/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-duplicate-props */
import {
  Box,
  Card,
  CardContent,
  TablePagination,
  TableSortLabel,
  Typography,
  TextField,
  InputAdornment,
  Skeleton,
  SxProps,
  Tooltip,
  IconButton,
  Button,
  Stack,
  TableCellProps,
  Select,
  MenuItem,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AxiosError } from "axios";
import ExportWalletHolder from "components/users/ExportWalletHolder";
import UserMenuAction from "components/users/MenuActions";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { IColumnProps } from "interfaces/mui-column";
import { IWalletHolderProps, USER_STATUS } from "interfaces/wallet-holder";
import { ChangeEvent, useEffect, useState, ReactElement, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showToast } from "redux/toast/action";
import {
  IFilterProps,
  getAllCardsForUser,
  getWalletHolders,
} from "utils/apiProvider";
import { t } from "utils/translate";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
  Clear,
} from "@mui/icons-material";
import colors from "constants/colors";
import { useQuery } from "utils/useQuery";
import { omit } from "utils/omit";
import { isDeeplyEqual } from "utils/isDeeplyEqual";
import {
  WhatsAppActionButton,
  EmailActionButton,
  CallActionButton,
  SendAccountNumberActionButton,
  TransferActionButton,
  SendInviteActionButton,
  AssignCardActionButton,
  MarkCardAsSentButton,
  PrintCardButton,
} from "components/wallet-holder/ActionButtons";
import {
  activationStatusColor,
  cardStatusColor,
  riskStatusColor,
  toSX,
  verificationStatusColor,
} from "utils/statusColors";
import { diffDaysNow } from "utils/dateDiffDays";
import { CardStatusList, cardsStatusText } from "utils/cardHelpers";
import { UTCLocalTimeStampCompact } from "components/shared/UTCLocalTimestampComponent";

dayjs.extend(relativeTime);

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const isFilterProps = (obj: unknown): obj is IFilterProps =>
  typeof obj === "object" &&
  !!obj &&
  "filter" in obj &&
  "cursor" in obj &&
  "limit" in obj &&
  "mode" in obj &&
  "sort" in obj &&
  typeof obj.filter === "object" &&
  !!obj.filter &&
  Object.entries(obj.filter).every(
    ([k, v]) => typeof k === "string" && typeof v === "string"
  ) &&
  (typeof obj.cursor === "string" || obj.cursor === null) &&
  typeof obj.limit === "number" &&
  typeof obj.mode === "string" &&
  (obj.mode === "or" || obj.mode === "and") &&
  Array.isArray(obj.sort) &&
  obj.sort.every((x) => Object.entries(x).length === 1);

const defaultFilter: IFilterProps = {
  cursor: null,
  limit: 10,
  filter: {
    activation_status__exact_not: "ARCHIVED",
  },
  mode: "or",
  sort: [
    {
      created_at: "desc",
    },
  ],
};

const getDefaultFilter = (): IFilterProps => {
  let decodedFilterFromQuery: null | IFilterProps = null;
  try {
    const queryFilters = JSON.parse(
      atob(new URL(window.document.URL).searchParams.get("filter") ?? "")
    );
    if (isFilterProps(queryFilters)) decodedFilterFromQuery = queryFilters;
  } catch (e) {
    console.log("Could not load filter from query", e);
  }
  return decodedFilterFromQuery ?? defaultFilter;
};

const WalletHolderList = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryRow = query.get("row");
  const [openRow, setOpenRow] = useState(queryRow || "");
  const [rows, setRows] = useState<IWalletHolderProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [nextCursor, setNextCursor] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState<IFilterProps>(getDefaultFilter());
  const enableClearButton = !isDeeplyEqual(
    omit(filter, "limit"),
    omit(omit(defaultFilter, "limit"))
  );
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState<string>(
    filter.filter?.["first_name__exact_case_insensitive"] ?? ""
  );
  const [hasUsedSearchInput, setHasUsedSearchInput] = useState(false);
  const debouncedSearchInput = useDebounce<string>(searchInput, 500);

  const ref = useRef<HTMLTableElement>();

  const refetchCards = () => {
    setRows((rows) =>
      rows.map((row) => ({ ...row, card_statuses: undefined }))
    );
    getCardsById(rows);
  };

  useEffect(() => {
    if (
      ref.current !== undefined &&
      typeof ref.current.clientHeight === "number" &&
      filter.limit === 0
    ) {
      const numRowsToFetch = Math.max(
        5,
        Math.min(25, Math.floor((ref.current?.clientHeight ?? 0) / 35) - 1)
      );
      setFilter((filter) => ({ ...filter, limit: numRowsToFetch }));
    }
  }, [ref.current?.clientHeight, filter.limit]);

  useEffect(() => {
    const url = new URL(window.document.URL);
    if (openRow) url.searchParams.set("row", String(openRow));
    else url.searchParams.delete("row");
    history.replaceState(null, "", url.toString());
  }, [openRow]);

  useEffect(() => {
    const url = new URL(window.document.URL);
    if (filter.limit) {
      url.searchParams.set("filter", btoa(JSON.stringify(filter)));
      history.replaceState(null, "", url.toString());
    }
  }, [JSON.stringify(filter)]);

  const updateRows = (id: string, changedValue: any) => {
    const findIndex = rows.findIndex((item: any) => item.id === id);
    if (findIndex > -1) {
      const uRows = [...rows];
      changedValue.forEach((item: any) => {
        uRows[findIndex] = {
          ...uRows[findIndex],
          [item.field]: item.value,
        };
      });
      setRows(uRows);
    }
  };

  type UserColumn = {
    field: string;
    headerName: string;
    sortable: boolean;
    formatter?: (p: IWalletHolderProps) => ReactElement | string;
    width?: number;
    cellSx?: (p: IWalletHolderProps) => SxProps;
    tableCellProps?: (p: IWalletHolderProps) => TableCellProps;
    expandedRowFormatter?: (p: IWalletHolderProps) => ReactElement | string;
  };

  const userColumns: UserColumn[] = [
    {
      width: 34,
      field: "id",
      headerName: "",
      sortable: false,
      cellSx(_p) {
        return {
          "&hover": {
            background: colors.GreyscaleDarkest,
            fontColor: colors.GreyscaleLightest,
          },
          cursor: "pointer",
        };
      },
      formatter(p) {
        const isOpen = p.id === openRow;
        return (
          <IconButton
            sx={{ position: "absolute", top: -1, left: 0 }}
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              setOpenRow(isOpen ? "" : p.id);
            }}
          >
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        );
      },
    },
    {
      width: 190,
      field: "first_name",
      headerName: t("first_name"),
      sortable: true,
      formatter: (p: IWalletHolderProps) => {
        const name = [p.first_name, p.last_name].filter((x) => x).join(" ");
        return (
          <Link
            style={{ color: `rgba(0, 0, 0, 0.87)` }}
            to={`/wallet-holder/show/${p.id}`}
          >
            <Tooltip placement={"right"} title={name}>
              <>{name}</>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      width: 250,
      field: "email",
      headerName: "Contact",
      sortable: false,
      formatter(p) {
        return (
          <Tooltip placement={"right"} title={p.email}>
            <span>{p.email}</span>
          </Tooltip>
        );
      },
      expandedRowFormatter(p) {
        return (
          <Stack direction="column" spacing={0}>
            <CallActionButton p={p} />
            <WhatsAppActionButton p={p} />
            <EmailActionButton p={p} />
          </Stack>
        );
      },
    },
    {
      width: 170,
      field: "wallet_number",
      headerName: "Account #",
      sortable: false,
      formatter(p) {
        return <>{p.wallet_number}</>;
      },
      expandedRowFormatter(p) {
        return (
          <Stack direction="column" spacing={0}>
            <SendAccountNumberActionButton p={p} />
            <TransferActionButton p={p} />
          </Stack>
        );
      },
    },
    {
      width: 130,
      field: "activation_status",
      headerName: "Activation",
      sortable: true,
      cellSx: (p) => toSX(activationStatusColor(p.activation_status)),
      formatter: (p: IWalletHolderProps) => {
        const s = p.activation_status.toLowerCase();
        const userStatus = USER_STATUS[s]
          ? USER_STATUS[s]
          : USER_STATUS.not_activated;
        return (
          <>
            {userStatus.name}
            {p.activation_status.toLowerCase() === USER_STATUS.invited.code &&
              p.last_status_change_at &&
              ` (${diffDaysNow(new Date(p.last_status_change_at))}d)`}{" "}
          </>
        );
      },
      expandedRowFormatter(p) {
        return <SendInviteActionButton onUpdatedProps={updateRows} p={p} />;
      },
    },
    {
      width: 140,
      field: "verification_status",
      headerName: "KYC",
      sortable: false,
      cellSx: (p) => ({
        textTransform: "capitalize",
        ...toSX(verificationStatusColor(p.verification_status)),
      }),
      formatter: (p: IWalletHolderProps) =>
        p.verification_status.toLowerCase().replace("_", " "),
    },
    {
      width: 120,
      field: "risk_status",
      headerName: "Risk",
      sortable: false,
      cellSx: (p) => ({
        textTransform: "capitalize",
        ...toSX(riskStatusColor(p.risk_status)),
      }),
      formatter: (p: IWalletHolderProps) =>
        p.risk_status.toLowerCase().replace("_", " "),
    },
    {
      width: 200,
      field: "card_status",
      headerName: "Physical card",
      sortable: false,
      cellSx(p) {
        const cards = p.card_statuses ?? [];
        return {
          textTransform: "capitalize",
          ...(cards.length === 0 ? {} : toSX(cardStatusColor(p.card_statuses))),
        };
      },
      formatter: (p: IWalletHolderProps) => {
        return p.card_statuses !== undefined ? (
          <>
            <Tooltip
              placement="left"
              title={<CardStatusList cardStatuses={p.card_statuses} />}
            >
              <span>{cardsStatusText(p.card_statuses)}</span>
            </Tooltip>
          </>
        ) : (
          <Skeleton />
        );
      },
      expandedRowFormatter(p) {
        return (
          <Stack direction="column" spacing={0}>
            {/* <RequestNewPhysicalCard p={p} onUpdatedProps={refetchCards} /> */}
            <AssignCardActionButton p={p} onUpdatedProps={refetchCards} />
            <PrintCardButton cards={p.card_statuses ?? []} walletHoldersName={`${p?.first_name} ${p?.last_name}`} showLast4Digits={true} />
            <MarkCardAsSentButton p={p} onUpdatedProps={refetchCards} />
          </Stack>
        );
      },
    },
    {
      width: 200,
      field: "created_at",
      headerName: "Created at",
      sortable: true,
      formatter: (p: IWalletHolderProps) => {
        return <UTCLocalTimeStampCompact date={p.created_at} />;
        // return <>{dayjs(p.created_at).format("MMM D, YYYY")}</>;
      },
    },
    {
      width: 200,
      field: "last_transaction",
      headerName: "Latest transaction",
      sortable: true,
      formatter: (p: IWalletHolderProps) => {
        if (p?.last_transaction) {
          return <>{dayjs(p.last_transaction).fromNow()}</>;
        }
        return <>No transactions</>;
      },
    },
  ];

  function Row(props: { row: any }) {
    const isOpen = openRow === props.row.id;
    return (
      <>
        <TableRow hover sx={{ textDecoration: "none" }} tabIndex={-1}>
          {userColumns.map((column) => {
            const value = props.row[column.field as keyof IWalletHolderProps];
            const sxProps: SxProps = {
              ...(column.cellSx?.(props.row) ?? {}),
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              position: "relative",
            };
            const tableCellProps = column.tableCellProps?.(props.row) ?? {};
            return (
              <TableCell sx={sxProps} {...tableCellProps} key={column.field}>
                {column.formatter ? column.formatter(props.row) : value}
                {isOpen && column.expandedRowFormatter ? (
                  <Box sx={{ display: "block", marginTop: 1 }}>
                    {column.expandedRowFormatter(props.row)}
                  </Box>
                ) : null}
              </TableCell>
            );
          })}
        </TableRow>
      </>
    );
  }

  const getCardsById = async (data: IWalletHolderProps[]) => {
    await Promise.all(
      data.map(async (row, index) => {
        const card_statuses =
          row.activation_status === "ARCHIVED"
            ? []
            : await getAllCardsForUser("" + row.id, "physical");

        setRows((rows) =>
          rows.map((user, i) =>
            i === index ? { ...row, card_statuses } : user
          )
        );
      })
    );
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const resp = await getWalletHolders({ ...filter, cursor: nextCursor });
      setRows(resp.data);
      setLoading(false);
      setTotal(resp.total_count);
      getCardsById(resp.data);
      setNextCursor(resp.next_cursor);
    } catch (err) {
      if (err instanceof AxiosError)
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title: "Can not load wallet holder",
            })
          );
        }
    }
  };

  const handleRowsPerPageChange = (e: ChangeEvent<any>) => {
    setNextCursor(null);
    setFilter({
      ...filter,
      cursor: null,
      limit: e.target.value,
    });
  };

  useEffect(() => {
    if (filter.limit !== 0) fetchUsers();
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    if (reload) {
      fetchUsers();
    }
  }, [reload]);

  useEffect(() => {
    setFilter((filter) => {
      setNextCursor(null);
      return {
        ...filter,
        cursor: null,
        filter: debouncedSearchInput
          ? {
            first_name__exact_case_insensitive: debouncedSearchInput,
            last_name__exact_case_insensitive: debouncedSearchInput,
            wallet_number__exact_case_insensitive: (debouncedSearchInput
              .toUpperCase()
              .includes("SB")
              ? debouncedSearchInput
              : `SB${debouncedSearchInput}`
            ).replace(
              /(\w{2})\s*(\w{4})\s*(\w{3})\s*(\w{3})\s*/g,
              "$1 $2 $3 $4"
            ),
            email__exact_case_insensitive: debouncedSearchInput,
          }
          : {
            ...Object.fromEntries(
              Object.entries(filter.filter ?? {}).filter(
                ([k]) =>
                  ![
                    "first_name__exact_case_insensitive",
                    "last_name__exact_case_insensitive",
                    "wallet_number__exact_case_insensitive",
                    "email__exact_case_insensitive",
                  ].includes(k)
              )
            ),
          },
      };
    });
  }, [debouncedSearchInput, hasUsedSearchInput]);

  const handleSort = (column: IColumnProps) => {
    const findExisted = filter.sort?.find(
      (s: any) => Object.keys(s)?.[0] === column.field
    );
    const order =
      findExisted?.[Object.keys(findExisted)?.[0]] === "desc" ? "asc" : "desc";
    setFilter({
      ...filter,
      sort: [
        {
          [column.field]: order,
        },
      ],
    });
  };

  const onChangeSearchInput = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setSearchInput(value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0",
          flexDirection: ["row-reverse", "row-reverse", "row", "row"],
        }}
      >
        <Typography sx={{ display: { xs: "none", md: "block" } }} variant="h1">
          {t("wallet_holder_page_title")}
        </Typography>
        <UserMenuAction setReload={setReload} />
      </Box>
      <Card>
        <CardContent sx={{ position: "relative", minHeight: 250 }}>
          <Box>
            <ExportWalletHolder />
          </Box>
          <Box>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              style={{ width: 420, marginBottom: 15 }}
              placeholder={"Search for names, emails, and accounts ..."}
              value={searchInput}
              onChange={onChangeSearchInput}
              onKeyDown={() => setHasUsedSearchInput(true)}
            ></TextField>
            <Select
              sx={{ marginLeft: 2, width: 200 }}
              value={JSON.stringify(
                Object.fromEntries(
                  Object.entries(filter.filter ?? {}).filter(
                    ([k]) =>
                      k === "activation_status__exact_not" ||
                      k === "activation_status__exact"
                  )
                )
              )}
              onChange={(e) => {
                setSearchInput("");
                setNextCursor(null);
                setPage(1);
                setFilter((filter) => ({
                  ...filter,
                  cursor: null,
                  filter: {
                    ...JSON.parse(e.target.value as string),
                  },
                }));
              }}
            >
              <MenuItem value={`{"activation_status__exact_not":"ARCHIVED"}`}>
                Non-archived users
              </MenuItem>
              <MenuItem value={`{"activation_status__exact":"ARCHIVED"}`}>
                Archived users
              </MenuItem>
              <MenuItem value={`{"activation_status__exact":"ACTIVATED"}`}>
                Active users
              </MenuItem>
              <MenuItem value={`{"activation_status__exact":"NOT_INVITED"}`}>
                Not invited users
              </MenuItem>
              <MenuItem value={`{"activation_status__exact":"INVITED"}`}>
                Invited users
              </MenuItem>
              <MenuItem value={`{}`}>All users</MenuItem>
            </Select>
            <Button
              onClick={() => {
                setHasUsedSearchInput(false);
                setSearchInput("");
                setPage(1);
                setNextCursor(null);
                setFilter(defaultFilter);
              }}
              disabled={!enableClearButton}
              sx={{
                marginLeft: 2,
                height: "40px",
                position: "relative",
                top: "-1px",
              }}
              variant="outlined"
              size="large"
              startIcon={<Clear />}
            >
              Clear
            </Button>
          </Box>
          {/* @ts-expect-error Ignore ref issue */}
          <TableContainer
            ref={ref}
            sx={{
              scrollbarGutter: "stable",
              flexGrow: 1,
              height: "calc(100vh - 350px)",
              marginTop: 0,
            }}
          >
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              sx={{
                tableLayout: "fixed",
              }}
            >
              <TableHead>
                <TableRow>
                  {userColumns.map((column: IColumnProps) => (
                    <TableCell
                      sx={{ backgroundColor: colors.Grey100 + " !important" }}
                      key={column.field}
                      width={column.width ? `${column.width}px` : "auto"}
                    >
                      {column.sortable ? (
                        <TableSortLabel
                          active={
                            !!filter.sort?.find(
                              (s: any) => Object.keys(s)?.[0] === column.field
                            )
                          }
                          onClick={() => handleSort(column)}
                          direction={
                            filter.sort?.find(
                              (s: any) => Object.keys(s)?.[0] === column.field
                            )?.[column.field]
                              ? filter.sort?.find(
                                (s: any) =>
                                  Object.keys(s)?.[0] === column.field
                              )?.[column.field]
                              : "desc"
                          }
                        >
                          {column.headerName}
                        </TableSortLabel>
                      ) : (
                        <>{column.headerName}</>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading
                  ? Array.from({ length: filter.limit }, (_, i) => (
                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                      {Array.from({ length: userColumns.length }, (_, i) => (
                        <TableCell
                          key={i}
                          width={
                            userColumns[i].width
                              ? `${userColumns[i].width}px`
                              : "auto"
                          }
                        >
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                  : rows.map((row: any) => {
                    return <Row key={row.id} row={row} />;
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={total}
            rowsPerPage={filter.limit}
            page={page - 1}
            onPageChange={(event, newPage) => {
              setPage(newPage + 1);
              setFilter({
                ...filter,
                cursor: nextCursor,
              });
            }}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default WalletHolderList;
