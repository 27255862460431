export default {
  PrimaryDarkest: "#001129",
  PrimaryDark: "#00327b",
  PrimaryDefault: "#0053cd",
  PrimaryLight: "#83b2eb",
  PrimaryLightest: "#e2ebf8",

  ErrorDarkest: "#322018",
  ErrorDark: "#962318",
  ErrorDefault: "#c83c23",
  ErrorLight: "#f9b7ac",
  ErrorLightest: "#fff0f0",

  SuccessDarkest: "#033822",
  SuccessDark: "#23584c",
  SuccessDefault: "#317e60",
  SuccessLight: "#95bba7",
  SuccessLightest: "#e4f3ec",

  WarningDarkest: "#63440a",
  WarningDark: "#9d6b34",
  WarningDefault: "#fcc358",
  WarningLight: "#ffdd8d",
  WarningLightest: "#fff3cd",

  GreyscaleBlack: "#182332",
  GreyscaleDarkest: "#43474e",
  GreyscaleDark: "#686e78",
  GreyscaleDefault: "#a3a7ad",
  GreyscaleLight: "#d4d6d9",
  GreyscaleLighter: "#ced4da",
  GreyscaleLightest: "#f5f6f7",

  Yellow: "#EEFF00",
  White: "#ffffff",
  Black: "#1E1832",
  BlueDark: "#1D213A",

  Grey700: "#5B5866",
  Grey500: "#A4A1AD",
  Grey300: "#D5D4D9",
  Grey100: "#F8F7FA",

  Neutral800: "#111133",
  Neutral600: "#444466",

  Primary500: "#5D31FF",
  Primary600: "#5533FF",
  Secondary500: "#381C9F",
  Secondary300: "#BEADFF",
  Secondary100: "#EFEAFF",
  Accent500: "#E4FE08",
  Accent700: "#B6CB06",

  Success500: "#2e7d32",
  Success300: "#e8f5e9",
  Error800: "#BB1111",
  Error100: "#FFEEEE",
  Error500: "#C62828",
  Error300: "#FFEBEE",
  Warning500: "#9A4A00",
  Warning300: "#FFF3E0",

  Neutral400: "#9999BB",
};
