import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardContent, Stack } from "@mui/material";
import { AxiosError } from "axios";
import TransactionComment from "components/transfer/TransactionComment";
import TransactionComplete from "components/transfer/TransactionComplete";
import TransactionForm from "components/transfer/TransactionForm";
import { Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import { IBankAccountProps, initBankAccount } from "interfaces/bank-account";
import { CustomError } from "interfaces/error";
import { ITransactionProps, initTransaction } from "interfaces/transaction";
import {
  IWalletHolderProps,
  RISK_STATUS,
  wallerHolderInit,
} from "interfaces/wallet-holder";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "redux/toast/action";
import {
  getAccounts,
  getWalletHolderDetail,
  p2pConfirm,
  p2pRequest,
} from "utils/apiProvider";
import authProvider from "utils/authProvider";

export const TransferProvider = createContext<{
  data: IWalletHolderProps;
  bankAccounts: IBankAccountProps[];
  activeAccount: IBankAccountProps | null;
  transfer: ITransactionProps;
  setTransfer: (t: ITransactionProps) => void;
  setActiveAccount: (t: IBankAccountProps) => void;
  transactionId: string;
}>({
  data: wallerHolderInit,
  bankAccounts: [initBankAccount],
  activeAccount: null,
  transfer: initTransaction,
  transactionId: "",
  setTransfer: (t) => console.warn(t),
  setActiveAccount: (t) => console.warn(t),
});

const UserTransferForm = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const userFormRef = useRef<FormikProps<ITransactionProps>>(null);
  const [step, setStep] = useState(1);
  const [data, setData] = useState<IWalletHolderProps>(wallerHolderInit);
  const [transfer, setTransfer] = useState<ITransactionProps>(initTransaction);
  const [transactionId, setTransactionId] = useState<string>("");
  const [bankAccounts, setBankAccounts] = useState<IBankAccountProps[]>([
    initBankAccount,
  ]);
  const [activeAccount, setActiveAccount] = useState<IBankAccountProps | null>(
    null
  );
  const [loaded, setLoaded] = useState(false);
  const fetchDetail = async (id: string) => {
    getWalletHolderDetail(id).then((resp) => {
      setData(resp);
      setLoaded(true);
    });
  };

  const fetchAccounts = async () => {
    const userId = await authProvider.getUserID();
    getAccounts(userId).then((resp: IBankAccountProps[]) => {
      const usdAccount = resp.find(
        (d: IBankAccountProps) => d.currency === "USD"
      );
      if (usdAccount) {
        setBankAccounts(resp);
        setActiveAccount(usdAccount);
      }
    });
  };

  useEffect(() => {
    if (params.id) {
      fetchAccounts();
      fetchDetail(params.id);
    }
  }, []);

  const submit = (
    values: ITransactionProps,
    { setSubmitting }: FormikHelpers<ITransactionProps>
  ) => {
    switch (step) {
      case 1:
        setTransfer({
          ...transfer,
          currency_code: values.currency_code,
          amount: values.amount,
          comments: values.comments,
          recipient_email: data.email,
        });
        p2pRequest({
          currency_code: values.currency_code,
          amount: values.amount.split(",").join(""),
          comments: values.comments,
          recipient_email: data.email,
        })
          .then((resp) => {
            setTransactionId(resp.transaction_id);
            setStep(step + 1);
            setSubmitting(false);
          })
          .catch((err: AxiosError<CustomError>) => {
            if (err.response?.status !== 401) {
              dispatch(
                showToast({
                  type: "error",
                  title:
                    err.response?.data.error.message ?? "Can not tranfer money",
                })
              );
            }
            setSubmitting(false);
          });
        break;
      case 2:
        p2pConfirm({
          transaction_id: transactionId,
          otp_code: values?.otp_code ?? "",
        })
          .then((data) => {
            setTransfer({
              ...transfer,
              id: data['id'], transacted_at: data['transacted_at']
            });
            setStep(step + 1);
            setSubmitting(false);
          })
          .catch((err: AxiosError<CustomError>) => {
            if (err.response?.status !== 401) {
              dispatch(
                showToast({
                  type: "error",
                  title:
                    err.response?.data.error.message ?? "Can not tranfer money",
                })
              );
            }
            setSubmitting(false);
          });
        break;
      default:
    }
  };

  const renderUiByStep = () => {
    switch (step) {
      case 2:
        return <TransactionComment transactionId={transactionId} />;
      case 3:
        return <TransactionComplete />;
      default:
        return <TransactionForm />;
    }
  };

  const contextData = useMemo(
    () => ({
      data,
      transfer,
      setTransfer,
      bankAccounts,
      setActiveAccount,
      activeAccount,
      transactionId,
    }),
    [data, bankAccounts, activeAccount, transactionId]
  );

  const validate = (values: ITransactionProps) => {
    const errors: FormikErrors<ITransactionProps> = {};
    const amount = values.amount.split(",").join("");
    if (!amount) {
      errors.amount = "Field cannot be left blank.";
    } else {
      const amountNum = +amount.split(".").join("");
      if (Number.isNaN(Math.sign(amountNum))) {
        errors.amount = "Please enter a valid amount.";
      } else if (Math.sign(amountNum) === -1) {
        errors.amount = "You have insufficient funds.";
      } else if (Math.sign(amountNum) === 0) {
        errors.amount = "Amount must be greater than 0.";
      }
    }
    if (!values.currency_code) {
      errors.currency_code = "Field cannot be left blank.";
    }
    if (
      activeAccount &&
      +values.amount.split(",").join("") > +activeAccount.balance
    ) {
      errors.amount = "You have insufficient funds.";
    }
    return errors;
  };

  if (!loaded) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card>
          <CardContent>Loading ...</CardContent>
        </Card>
      </Box>
    );
  }

  if (data.risk_status.toLowerCase() !== RISK_STATUS.CLEARED) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card>
          <CardContent>Can not transfer to this user.</CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <TransferProvider.Provider value={contextData}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ width: 400 }}>
          <CardContent sx={{ margin: ["10px", "10px", "15px"] }}>
            {step < 4 && (
              <Box>
                <ArrowBackIcon
                  onClick={() => {
                    if (step > 1) {
                      setStep(step - 1);
                    } else {
                      navigate("/");
                    }
                  }}
                />
              </Box>
            )}
            <Formik
              innerRef={userFormRef}
              initialValues={transfer}
              onSubmit={submit}
              validate={validate}
              enableReinitialize
            >
              {({ handleSubmit, isSubmitting, values, isValid, dirty }) => (
                <form onSubmit={handleSubmit}>
                  <Stack gap={2} mt={4}>
                    {renderUiByStep()}
                    {step !== 3 && (
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={
                          isSubmitting ||
                          (step === 1 && !(isValid && dirty)) ||
                          (step === 2 && values.otp_code?.length !== 6)
                        }
                      >
                        {step === 2 ? "Confirm" : "Continue"}
                      </Button>
                    )}
                  </Stack>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </TransferProvider.Provider>
  );
};

export default UserTransferForm;
