import { Box, Card, CardContent } from "@mui/material";
import WalletHolderTabMenu from "components/users/Menu";
import UserProfile from "components/users/UserProfile";
import WhDetailHeader from "components/wallet-holder/detail/WhDetailHeader";
import { ICountryProps } from "interfaces/common";
import { IWalletHolderProps, wallerHolderInit } from "interfaces/wallet-holder";
import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getAllCardsForUser,
  getCountries,
  getWalletHolderDetail,
} from "utils/apiProvider";

const WalletHolderDetail = () => {
  const params = useParams<{ id: string }>();
  const [countries, setCountries] = useState<ICountryProps[]>([]);
  // const [data, setData] = useState<IWalletHolderProps>(wallerHolderInit);
  const [wh, setWh] = useState<IWalletHolderProps>(wallerHolderInit);

  // const fetchDetail = async (id: string) => {
  //   setLoading(true);
  //   getWalletHolderDetail(id)
  //     .then((resp) => {
  //       setData(resp);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       if (err.response?.status !== 401) {
  //         dispatch(
  //           showToast({
  //             type: "error",
  //             title: "Can not fetch the user",
  //           })
  //         );
  //       }
  //     });
  // };

  const fetchAllDetail = async (id: string) => {
    const [wh, card_statuses] = await Promise.all([
      getWalletHolderDetail(id),
      getAllCardsForUser("" + id, "all"),
    ]);
    setWh({ ...wh, card_statuses });
  };

  useEffect(() => {
    getCountries().then((res) => {
      setCountries(res);
    });
    if (params.id) {
      fetchAllDetail(params.id);
    }
  }, []);

  const getCountry = (code: string) => {
    return countries.find((item) => item.code === code)?.description ?? "";
  };

  return (
    <Box>
      <WhDetailHeader data={wh} id={wh.id} />
      <WalletHolderContext.Provider value={wh}>
        <Card>
          <CardContent sx={{ margin: ["10px", "10px", "20px"] }}>
            <WalletHolderTabMenu id={wh.id} />
            <UserProfile data={wh} getCountry={getCountry} />
          </CardContent>
        </Card>
      </WalletHolderContext.Provider>
    </Box>
  );
};

export default WalletHolderDetail;

export const WalletHolderContext = createContext<
  IWalletHolderProps | undefined
>(undefined);
