import { Box, Button, Grid, Typography } from "@mui/material";
import { UTCLocalTimeStampCompact } from "components/shared/UTCLocalTimestampComponent";
import colors from "constants/colors";
import { useFormikContext } from "formik";
import { ITransactionProps } from "interfaces/transaction";
import { TransferProvider } from "pages/users/transfer";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { formatMoney, formatMoneyWithDecimal } from "utils/formatMoney";
import { t } from "utils/translate";

const TransactionComplete = () => {
  const { data } = useContext(TransferProvider);
  const { values } = useFormikContext<ITransactionProps>();
  const navigate = useNavigate();

  return (
    <>
      <Box textAlign="center">
        <img
          src={`${process.env.PUBLIC_URL}/images/Icon.png`}
          alt="success"
          width="80px"
        />
      </Box>
      <Box>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Transfer Complete
        </Typography>
      </Box>
      <Box>
        <Box>
          <Typography sx={{ textAlign: "center" }} fontSize={16}>
            You have successfully transferred{" "}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
        >
          <Typography sx={{ color: colors.Primary500 }} fontWeight={500}>
            {values?.currency_code}{" "}
            {formatMoneyWithDecimal(formatMoney(values?.amount))}
          </Typography>
          <Typography sx={{ marginLeft: "3px", marginRight: "3px" }}>
            to
          </Typography>{" "}
          <Typography
            sx={{ textAlign: "center", color: colors.Primary500 }}
            fontWeight={500}
          >{`${data.first_name} ${data.last_name}`}</Typography>
        </Box>

        {values.id && <Box>
          <KeyValueView title={t("date_of_transaction")} value={!values.transacted_at ? "-" : <UTCLocalTimeStampCompact date={values.transacted_at} />} />
          <KeyValueView title={t("reference_no")} value={values.id} />
          <KeyValueView title={t("comments_for_recipient")} value={values.comments} />
        </Box>}
        <Box mt="35px">
          <Button
            variant="contained"
            onClick={() => navigate(`/accounts/${values?.currency_code}`)}
            fullWidth
          >
            Done
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TransactionComplete;

export const KeyValueView = ({ title, value }: { title?: any, value?: any }) => {
  return <Grid container columns={{ md: 10 }} mt={2}>
    <Grid item xs={5} >
      <Typography variant="body1" gutterBottom fontSize={14} color={colors.Neutral600}>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={5} >
      <Box style={{ flexWrap: 'wrap', wordWrap: 'break-word' }}>
        <Typography variant="body1" gutterBottom noWrap={false} fontSize={14} color={colors.Neutral800}>
          {value == null || value === '' ? "-" : value}
        </Typography>
      </Box>
    </Grid>
  </Grid>
}
